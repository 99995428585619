import revive_payload_client_FuPj1ELJOI from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.70_db0@0.2.1_encoding@0.1.13_ioredis@5.4._whocksnf65hhxc3p6tktjtjx7e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JBnUX4Q6lD from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.70_db0@0.2.1_encoding@0.1.13_ioredis@5.4._whocksnf65hhxc3p6tktjtjx7e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aMyhMpMqqq from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.70_db0@0.2.1_encoding@0.1.13_ioredis@5.4._whocksnf65hhxc3p6tktjtjx7e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_U1FIgp3UBK from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.70_db0@0.2.1_encoding@0.1.13_ioredis@5.4._whocksnf65hhxc3p6tktjtjx7e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_P7PJAvVJZ4 from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.70_db0@0.2.1_encoding@0.1.13_ioredis@5.4._whocksnf65hhxc3p6tktjtjx7e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_2wOT6DTZMW from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.70_db0@0.2.1_encoding@0.1.13_ioredis@5.4._whocksnf65hhxc3p6tktjtjx7e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GHQqbUadrv from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.70_db0@0.2.1_encoding@0.1.13_ioredis@5.4._whocksnf65hhxc3p6tktjtjx7e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mNOnrMYYgt from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.30.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/.nuxt/components.plugin.mjs";
import prefetch_client_iGOlNif6el from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.70_db0@0.2.1_encoding@0.1.13_ioredis@5.4._whocksnf65hhxc3p6tktjtjx7e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/.nuxt/primevue-plugin.mjs";
import plugin_client_V7cRxGRHaJ from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/node_modules/.pnpm/nuxt-primevue@0.2.2_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import lang_G9PR3PZD5Q from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/plugins/lang.js";
import loading_JsMeH12IfP from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/plugins/loading.js";
import v_md_editor_P2TPih41PN from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/merchant-backend/plugins/v-md-editor.js";
export default [
  revive_payload_client_FuPj1ELJOI,
  unhead_JBnUX4Q6lD,
  router_aMyhMpMqqq,
  payload_client_U1FIgp3UBK,
  navigation_repaint_client_P7PJAvVJZ4,
  check_outdated_build_client_2wOT6DTZMW,
  chunk_reload_client_GHQqbUadrv,
  plugin_vue3_mNOnrMYYgt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iGOlNif6el,
  primevue_plugin_egKpok8Auk,
  plugin_client_V7cRxGRHaJ,
  lang_G9PR3PZD5Q,
  loading_JsMeH12IfP,
  v_md_editor_P2TPih41PN
]